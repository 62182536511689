import React from 'react';

import { useTabsPrefixContext } from './TabsPrefixContext';
import { ClassNameProps } from '@ComponentProps';
import { SelectableCollapse } from '@UX/Collapse/Collapse';

export interface TabProps extends ClassNameProps {
  index?: number | string;
  title: string;
  lazy?: boolean;
}

export const Tab: React.FC<TabProps> = ({ children, className, index, lazy }) => {
  const prefix = useTabsPrefixContext();

  return (
    <SelectableCollapse
      id={`tabpanel_${prefix}_${index}`}
      role="tabpanel"
      aria-labelledby={`tab_${prefix}_${index}`}
      lazy={lazy}
      className={className}
    >
      {children}
    </SelectableCollapse>
  );
};
